import React, { Suspense, lazy } from "react";

import { BrowserRouter, Route, Routes } from "react-router-dom";
import { QueryClient, QueryClientProvider } from "@tanstack/react-query";

import { Footer, Spinner } from "./components";

const Home = lazy(() => import("./pages/Home"));
const Setup = lazy(() => import("./pages/Setup"));
const Dummy = lazy(() => import("./pages/Dummy"));
const NotFound = lazy(() => import("./pages/NotFound"));
const ThankYou = lazy(() => import("./pages/ThankYou"));

const queryClient = new QueryClient();

const App: React.FC = () => {
  return (
    <QueryClientProvider client={queryClient}>
      <Suspense fallback={<Spinner />}>
        <BrowserRouter>
          <Routes>
            <Route path="*" element={<NotFound />} />
            <Route path="/:token/:type" element={<Home />} />
            <Route path="/Dummy" element={<Dummy />} />
            <Route path="/Setup/:token" element={<Setup />} />
            <Route path="/ThankYou/:token" element={<ThankYou />} />
          </Routes>
          <Footer />
        </BrowserRouter>
      </Suspense>
    </QueryClientProvider>
  );
};

export default App;
