import { api } from "../api";

import {
  AppConfigurationType,
  ConfigurationType,
  PlanResponseType,
  PlansResponseType,
} from "../types";

import { KeyValue } from "../constants";

export const getPlans = async (token: string, type: string) => {
  const { data } = await api.get<PlansResponseType[]>(
    `/pagbank-plans?type=${type}`,
    {
      headers: {
        [KeyValue.X_ONBOARDING_TOKEN_KEY]: token,
      },
    }
  );
  return data;
};

export const getPlan = async (token: string, planId: string) => {
  const { data } = await api.get<PlanResponseType>(
    `/connect-url/plan/${planId}`,
    {
      headers: {
        [KeyValue.X_ONBOARDING_TOKEN_KEY]: token,
      },
    }
  );
  return data;
};

export const getConfiguration = async (token: string) => {
  const { data } = await api.get<ConfigurationType>(`/configuration`, {
    headers: {
      [KeyValue.X_ONBOARDING_TOKEN_KEY]: token,
    },
  });
  return data;
};

export const changeConfiguration = async (
  token: string,
  body: ConfigurationType
) => {
  const { data } = await api.patch<ConfigurationType>(`/configuration`, body, {
    headers: {
      [KeyValue.X_ONBOARDING_TOKEN_KEY]: token,
    },
  });
  return data;
};

export const getAppConfigurationURL = async (token: string) => {
  const { data } = await api.get<AppConfigurationType>(
    `/app-configuration-url`,
    {
      headers: {
        [KeyValue.X_ONBOARDING_TOKEN_KEY]: token,
      },
    }
  );
  return data;
};
