import React from "react";

type Props = {
  width?: string;
  height?: string;
  border?: string;
};

const Separator: React.FC<Props> = ({ width, height, border }) => {
  return <div style={{ width, height, border }} />;
};

export default Separator;
