import React, { ReactNode } from "react";

import styled from "styled-components";

import Span from "../Span";

import { Colors } from "../../../constants";

type Props = {
  id: string;
  outline?: boolean;
  backgroundColor?: string;
  color?: string;
  fontSize?: string;
  fontWeight?: string;
  lineHeight?: string;
  wordWrap?: string;
  margin?: string;
  maxWidth?: string;
  disabled?: boolean;
  onClick?: () => void;
  children: ReactNode;
};

const ButtonTag = styled.button<Props>`
  display: flex;
  flex-shrink: 0;
  border-radius: 2px;

  cursor: pointer;
  padding: 13px 40px;
  justify-content: center;
  align-items: center;
  gap: 8px;

  ${({ backgroundColor }) =>
    backgroundColor && `background-color: ${backgroundColor};`}
  ${({ margin }) => margin && `margin: ${margin};`}
  ${({ lineHeight }) => lineHeight && `line-height: ${lineHeight};`}
  ${({ outline }) => outline && `background-color: transparent;`}
  ${({ backgroundColor }) => `border: 1px solid ${backgroundColor};`}
  ${({ disabled, outline }) =>
    disabled &&
    !outline &&
    `disabled: ${disabled}; background-color: ${Colors.SILVER}; border: 1px solid ${Colors.SILVER};`}
`;

const Button: React.FC<Props> = ({
  id,
  outline = false,
  backgroundColor = Colors.MOUNTAIN_MEADOW,
  color = Colors.BLACK,
  fontSize = "18px",
  fontWeight = "300",
  lineHeight = "20px",
  wordWrap = "break-word",
  margin = "0px 0px 0px 0px",
  maxWidth = "243px",
  disabled = false,
  onClick,
  children,
}) => {
  return (
    <ButtonTag
      id={id}
      name={id}
      backgroundColor={backgroundColor}
      margin={margin}
      onClick={onClick}
      outline={outline}
      maxWidth={maxWidth}
      disabled={disabled}
    >
      <Span
        color={color}
        fontSize={fontSize}
        fontWeight={fontWeight}
        lineHeight={lineHeight}
        wordWrap={wordWrap}
      >
        {children}
      </Span>
    </ButtonTag>
  );
};

export default Button;
