import React from "react";
import styled from "styled-components";

type Props = {
  src?: string;
  alt?: string;
  margin?: string;
  width?: string;
  height?: string;
};

const ImageTag = styled.img<Props>`
  ${({ margin }) => margin && `margin: ${margin};`}
  ${({ width }) => width && `width: ${width};`}
  ${({ height }) => height && `height: ${height};`}
`;

const Image: React.FC<Props> = ({ src, alt = "", margin, width, height }) => {
  return (
    <ImageTag
      src={src}
      alt={alt}
      margin={margin}
      width={width}
      height={height}
    />
  );
};

export default Image;
