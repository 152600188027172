import React from "react";

import { useMediaQuery } from "../../../hooks";

import { ErrorResponseType } from "../../../types";

import Span from "../Span";
import Image from "../Image";
import Wrapper from "../Wrapper";
import Paragraph from "../Paragraph";

import { Colors, Configs } from "../../../constants";

import { WarningSVG } from "../../../assets/images";

interface Props {
  error: ErrorResponseType;
  onRetry?: Function;
}

const Warning: React.FC<Props> = ({ error, onRetry }) => {
  const isMobile = useMediaQuery(Configs.MOBILE_SIZE);

  return (
    <Wrapper
      display="flex"
      padding={isMobile ? "45px" : "90px"}
      justifyContent="center"
    >
      <Wrapper
        display="flex"
        flexDirection="column"
        maxWidth={isMobile ? "313px" : "460px"}
        maxHeight={isMobile ? "108px" : "168px"}
        padding={isMobile ? "80px 28px" : "60px 100px"}
        alignItems="center"
        justifyContent="center"
        borderRadius="2px"
        border={`1px solid ${Colors.SILVER}`}
        backgroundColor={Colors.WHITE}
        boxShadow="0px 2px 2px 0px rgba(0, 0, 0, 0.24), 0px 0px 2px 0px rgba(0, 0, 0, 0.12)"
        gap="16px"
      >
        <Image src={WarningSVG} />
        {error?.message && (
          <Paragraph fontSize={isMobile ? "16px" : "22px"}>
            {error?.message}
          </Paragraph>
        )}

        <Span
          color={Colors.CYAN_CORNFLOWER_BLUE}
          textAlign="center"
          fontSize={isMobile ? "16px" : "22px"}
          fontWeight="700"
          cursor="pointer"
          onClick={onRetry}
        >
          Tentar novamente
        </Span>
      </Wrapper>
    </Wrapper>
  );
};

export default Warning;
