import React from "react";

import "./styles.css";

interface Props {
  width?: string;
  height?: string;
  border?: string;
}

const Spinner: React.FC<Props> = ({
  width = "50px",
  height = "50px",
  border = "3px solid #FFFFFF",
}) => {
  return (
    <div
      style={{
        width: width,
        height: height,
        border: border,
        borderTop: "3px solid #8BC63E",
        borderRadius: "50%",
        animation: "spinner 1.5s linear infinite",
      }}
    />
  );
};

export default Spinner;
