import React, { ChangeEvent } from "react";

import styled from "styled-components";

import { SwitchNamesType } from "../../../types";

import { Colors } from "../../../constants";

import ChevronDownSVG from "../../../assets/images/ic_chevron_down.svg";

const data = Array.from(Array(12), (_, i) => i + 1);

type Props = {
  name: SwitchNamesType;
  placeholder?: string;
  fontSize?: string;
  disabled?: boolean;
  initValue?: number;
  onChange?: (event: ChangeEvent<HTMLSelectElement>) => void;
};

export const SelectTag = styled.select<Props | HTMLSelectElement>`
  color: ${Colors.DARK_CHARCOAL};
  font-family: "UOLdisplay";
  font-style: normal;
  font-weight: 400;
  line-height: 22px;

  width: 100%;
  padding: 14px 16px;
  border-radius: 2px;
  border: 1px solid ${Colors.SILVER};
  background: ${Colors.WHITE};
  appearance: none;
  background: url(${ChevronDownSVG}) no-repeat right ${Colors.WHITE};
  background-position-x: 98%;

  ${({ fontSize }) => fontSize && `font-size: ${fontSize};`}
`;

const Select: React.FC<Props> = ({
  name,
  placeholder,
  fontSize = "20px",
  disabled,
  initValue = 0,
  onChange,
}) => {
  return (
    <SelectTag
      name={name}
      disabled={disabled}
      fontSize={fontSize}
      onChange={onChange}
    >
      <option value="">{placeholder}</option>

      {data?.map((option) => {
        const selected = initValue === option;

        return (
          <option key={option} value={option} selected={selected}>
            {option}
          </option>
        );
      })}
    </SelectTag>
  );
};

export default Select;
