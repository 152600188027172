import { useQuery } from "@tanstack/react-query";

import { getConfiguration } from "./../services";

const useConfiguration = (token: string) => {
  const {
    data,
    error,
    isLoading: isFetching,
  } = useQuery({
    queryKey: ["configuration"],
    queryFn: async () => getConfiguration(token),
  });

  return { data, error, isFetching };
};

export default useConfiguration;
