import axios, { AxiosError, AxiosResponse } from "axios";

import { getSessionId } from "./../utils";

import { ErrorResponseType } from "../types";

import { HttpStatusCodes, KeyValue, Strings } from "../constants";

import env from "../env.json";

const { baseURL, mockMode } = env;

const handleError = (error: AxiosError) => {
  const data = error?.response?.data as ErrorResponseType;

  if (error.response) {
    switch (data.responseCode) {
      case HttpStatusCodes.NOT_FOUND:
      case HttpStatusCodes.REQUEST_TIMEOUT:
      case HttpStatusCodes.INTERNAL_SERVER_ERROR:
      case HttpStatusCodes.NOT_IMPLEMENTED:
      case HttpStatusCodes.BAD_GATEWAY:
      case HttpStatusCodes.SERVICE_UNAVAILABLE:
      case HttpStatusCodes.GATEWAY_TIMEOUT:
        return {
          statusCode: data?.responseCode,
          message: Strings.SOMETHING_WENT_WRONG,
        };
      default:
        return {
          statusCode: data?.responseCode,
          message: data.cause?.errors[0]?.message,
        };
    }
  } else if (error.request) {
    return {
      statusCode: HttpStatusCodes.BAD_REQUEST,
      message: Strings.NO_INTERNET_CONNECTION,
    };
  } else {
    return {
      statusCode: data?.responseCode,
      message: data.cause?.errors[0]?.message,
    };
  }
};

const statusValidator = ({ status }: AxiosResponse<any>): boolean => {
  return (
    status >= HttpStatusCodes.OK && status < HttpStatusCodes.MULTIPLE_CHOICES
  );
};

const headers = {
  [KeyValue.MOCK_MODE_KEY]: mockMode,
  [KeyValue.CONTENT_TYPE_KEY]: KeyValue.CONTENT_TYPE_VALUE,
  [KeyValue.X_ONBOARDING_SESSION_ID_KEY]: getSessionId(),
};

export const api = axios.create({
  baseURL,
  headers,
  validateStatus: (status: any) =>
    statusValidator({ status } as AxiosResponse<any>),
});

api.interceptors.request.use(
  async (request) => {
    const data = request.data;
    const method = request.method;
    const url = baseURL + request.url;

    console.log(`-> [${method}] - ${url}`);
    data && console.log(`BODY: ${JSON.stringify(data, null, 2)}`);

    return request;
  },
  (error: AxiosError) => {
    return Promise.reject(handleError(error));
  }
);

api.interceptors.response.use(
  (response) => {
    const data = response.data;

    const method = response.config.method;
    const status = response.status;
    const url = baseURL + response.config.url;

    console.log(`<- [${method}] - ${url} - [${status}]`);
    data && console.log(`BODY: ${JSON.stringify(data, null, 2)}`);

    return response;
  },
  async (error: AxiosError) => {
    return Promise.reject(handleError(error));
  }
);
