import React, { ReactNode } from "react";

import styled from "styled-components";

import { Colors } from "../../../constants";

type Props = {
  color?: string;
  fontSize?: string;
  fontWeight?: string;
  lineHeight?: string;
  margin?: string;
  wordWrap?: string;
  textAlign?: string;
  onClick?: Function;
  cursor?: string;
  uppercase?: boolean;
  height?: string;
  children?: ReactNode;
};

const SpanTag = styled.span<Props>`
  font-family: "UOLText";
  font-style: normal;

  color: ${({ color }) => color};
  font-size: ${({ fontSize }) => fontSize};
  ${({ fontWeight }) => fontWeight && `font-weight: ${fontWeight};`};
  ${({ lineHeight }) => lineHeight && `line-height: ${lineHeight};`};
  ${({ margin }) => margin && `margin: ${margin};`};
  ${({ wordWrap }) => wordWrap && `word-wrap: ${wordWrap};`};
  ${({ textAlign }) => textAlign && `text-align: ${textAlign};`};
  ${({ cursor }) => cursor && `cursor: ${cursor};`};
  text-transform: ${({ uppercase }) => (uppercase ? "uppercase" : "none")};
  ${({ height }) => height && `height: ${height};`};
`;

const Span: React.FC<Props> = ({
  color = Colors.BLACK,
  fontSize = "14px",
  fontWeight,
  lineHeight,
  margin,
  wordWrap,
  textAlign,
  onClick,
  cursor,
  uppercase,
  height,
  children,
}) => {
  return (
    <SpanTag
      color={color}
      fontSize={fontSize}
      fontWeight={fontWeight}
      lineHeight={lineHeight}
      margin={margin}
      wordWrap={wordWrap}
      textAlign={textAlign}
      onClick={onClick}
      uppercase={uppercase}
      cursor={cursor}
      height={height}
    >
      {children}
    </SpanTag>
  );
};

export default Span;
