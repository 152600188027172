import React, { ReactNode } from "react";

import styled from "styled-components";

import { Colors } from "../../../constants";

type Props = {
  color?: string;
  fontSize?: string;
  fontWeight?: string;
  lineHeight?: string;
  margin?: string;
  wordWrap?: string;
  textAlign?: string;
  children?: ReactNode;
};

const ParagraphTag = styled.p<Props>`
  font-family: "UOLText";
  font-style: normal;

  color: ${({ color }) => color};
  font-size: ${({ fontSize }) => fontSize};
  ${({ fontWeight }) => fontWeight && `font-weight: ${fontWeight};`};
  ${({ lineHeight }) => lineHeight && `line-height: ${lineHeight};`};
  ${({ margin }) => margin && `margin: ${margin};`};
  ${({ wordWrap }) => wordWrap && `word-wrap: ${wordWrap};`};
  ${({ textAlign }) => textAlign && `text-align: ${textAlign};`};
`;

const Paragraph: React.FC<Props> = ({
  color = Colors.BLACK,
  fontSize = "14px",
  fontWeight,
  lineHeight,
  margin,
  wordWrap,
  textAlign,
  children,
}) => {
  return (
    <ParagraphTag
      color={color}
      fontSize={fontSize}
      fontWeight={fontWeight}
      lineHeight={lineHeight}
      margin={margin}
      wordWrap={wordWrap}
      textAlign={textAlign}
    >
      {children}
    </ParagraphTag>
  );
};

export default Paragraph;
