import React from "react";

import Carousel from "nuka-carousel";

import { useMediaQuery } from "../../hooks";

import { PlansResponseType } from "../../types";

import { formatNumberToCurrency, formatNumberToPercentage } from "../../utils";

import Span from "../shared/Span";
import Image from "../shared/Image";
import Button from "../shared/Button";
import Wrapper from "../shared/Wrapper";

import {
  CalendarioTempoSVG,
  CartaoCreditoSVG,
  CartaoTimeSVG,
  SemCustoSVG,
} from "../../assets/images";

import { Colors, Configs, Strings } from "../../constants";

type ItemProps = {
  data: PlansResponseType;
  onClick: (id: string) => void;
};

const Item = ({ data, onClick }: ItemProps) => {
  const isMobile = useMediaQuery(Configs.MOBILE_SIZE);
  const creditTaxPercentage = formatNumberToPercentage(
    data.credit_tax_percentage ?? 0
  );
  const gatewayTaxValue = formatNumberToCurrency(data.gateway_tax_value ?? 0);

  const handleClick = () => {
    onClick(data.id);
  };

  return (
    <Wrapper
      display="flex"
      flexDirection="column"
      alignItems="center"
      width={isMobile ? "360px" : "480px"}
      height={isMobile ? undefined : "516px"}
      borderRadius="10px"
      backgroundColor={Colors.WHITE}
      boxShadow="0px 12px 54px 0px rgba(0, 0, 0, 0.20)"
      margin="16px 16px"
    >
      <Wrapper
        display="flex"
        flexDirection="column"
        justifyContent="center"
        alignItems="center"
        height="65px"
        borderRadius="10px 10px 0px 0px"
        backgroundColor={Colors.MOUNTAIN_MEADOW}
        width="100%"
      >
        <Span
          color={Colors.WHITE}
          fontSize={isMobile ? "14px" : "20px"}
          fontWeight="700"
        >
          Receba em {data.receivable_days} dias
        </Span>
      </Wrapper>

      <Wrapper
        display="flex"
        flexDirection="column"
        justifyContent="center"
        alignItems="center"
        padding={isMobile ? "16px" : undefined}
      >
        <Wrapper
          display="flex"
          flexDirection="column"
          padding="48px"
          width="100%"
          gap="16px"
        >
          <Wrapper display="flex" alignItems="center" gap="8px">
            <Image src={SemCustoSVG} />
            <Span
              color={Colors.DOVE_GREY}
              fontSize="14px"
              fontWeight="400"
              lineHeight="24px"
            >
              Sem mensalidade e taxa de adesão
            </Span>
          </Wrapper>
          <Wrapper display="flex" alignItems="center">
            <Image src={CalendarioTempoSVG} margin="0px 8px 0px 0px" />
            <Span
              color={Colors.DOVE_GREY}
              fontSize="14px"
              fontWeight="400"
              lineHeight="24px"
            >
              Antecipe recebimento quando quiser
            </Span>
          </Wrapper>
          <Wrapper display="flex" alignItems="center">
            <Image src={CartaoTimeSVG} margin="0px 8px 0px 0px" />
            <Span
              color={Colors.DOVE_GREY}
              fontSize="14px"
              fontWeight="400"
              lineHeight="24px"
            >
              {`Venda em até 12x e receba tudo em ${data?.receivable_days} dias`}
            </Span>
          </Wrapper>
          <Wrapper display="flex" alignItems="center">
            <Image src={CartaoCreditoSVG} margin="0px 8px 0px 0px" />
            <Span
              color={Colors.DOVE_GREY}
              fontSize="14px"
              fontWeight="400"
              lineHeight="24px"
            >
              {`${creditTaxPercentage} + ${gatewayTaxValue} no crédito`}
            </Span>
          </Wrapper>
        </Wrapper>
        <Wrapper
          display="flex"
          flexDirection="column"
          width="100%"
          position={isMobile ? undefined : "absolute"}
          bottom="48px"
        >
          <Wrapper
            display="flex"
            justifyContent="center"
            alignItems="center"
            width="100%"
          >
            <Button
              id="choose_plan"
              color={Colors.WHITE}
              fontSize={isMobile ? "12px" : "18px"}
              fontWeight="300"
              onClick={handleClick}
              maxWidth={isMobile ? "160px" : "243px"}
            >
              Escolher esse
            </Button>
          </Wrapper>
        </Wrapper>
      </Wrapper>
    </Wrapper>
  );
};

type Props = {
  data?: PlansResponseType[];
  onClick: (id: string) => void;
};

const HOCCarousel: React.FC<Props> = ({ data, onClick }) => {
  const isMobile = useMediaQuery(Configs.MOBILE_SIZE);
    const plans = data?.filter((item) => !item.custom);
  const customPlans = data?.filter((item) => item.custom);

  return (
    <Wrapper
      display="flex"
      flexDirection="column"
      width="100%"
      justifyContent="center"
      alignItems={isMobile ? "flex-start" : "center"}
      gap={isMobile ? "33px" : "58px"}
    >
      <Wrapper display="flex" padding="16px 16px 16px 0">
        <Carousel
          withoutControls
          slidesToShow={isMobile ? 1 : 2}
          dragging={isMobile}
        >
          {plans?.map((plan) => (
            <Item key={plan.id} data={plan} onClick={onClick} />
          ))}
        </Carousel>
      </Wrapper>

      <Wrapper
        display="flex"
        width="100%"
        flexDirection="column"
        justifyContent="center"
        alignItems="center"
      >
        {customPlans?.map((plan) => (
          <Span
            color={Colors.DENIM}
            fontSize={isMobile ? "15px" : "24px"}
            fontWeight="400"
            textAlign="center"
          >
            Caso já tenha a condição comercial negociada
            <br /> com o PagBank,
            <Span
              color={Colors.DENIM}
              fontSize="inhered"
              fontWeight="400"
              textAlign="center"
              cursor="pointer"
              onClick={() => onClick(plan.id)}
            >
              {Strings.CLICK_HERE}
            </Span>
          </Span>
        ))}
      </Wrapper>
    </Wrapper>
  );
};

export default HOCCarousel;
