import { useQuery } from "@tanstack/react-query";

import { getAppConfigurationURL } from "./../services";

const useAppConfigurationURL = (token: string) => {
  const {
    data,
    error,
    isLoading: isFetching,
  } = useQuery({
    queryKey: ["app_configuration_url"],
    queryFn: async () => getAppConfigurationURL(token),
  });

  return { data, error, isFetching };
};

export default useAppConfigurationURL;
