import React from "react";

import { useMediaQuery } from "../../hooks";

import Image from "../shared/Image";

import { PagBankSVG } from "../../assets/images";

import { Colors, Configs } from "../../constants";

type Props = {
  v2?: boolean;
};

const Header: React.FC<Props> = ({ v2 = false }) => {
  const isMobile = useMediaQuery(Configs.MOBILE_SIZE);
  const defaultPadding = v2 ? "23px 54px" : "23px 129px";

  return (
    <header
      style={{
        display: "flex",
        position: "relative",
        alignItems: "center",
        justifyContent: "start",
        backgroundColor: v2 ? "#F6F6F6" : Colors.WHITE,
        padding: isMobile ? "14px" : defaultPadding,
        boxShadow: v2 ? "0px" : "0px 2px 4px rgba(0, 0, 0, 0.16)",
      }}
    >
      <Image src={PagBankSVG} />
    </header>
  );
};

export default Header;
