import React, { ChangeEvent } from "react";

import styled from "styled-components";

import { SwitchNamesType } from "../../../types";
import { Colors } from "../../../constants";

type Props = {
  id: SwitchNamesType;
  placeHolder?: string;
  disabled?: boolean;
  fontSize?: string;
  type?: string;
  inputMode?: string;
  value?: string;
  onChange?: (event: ChangeEvent<HTMLInputElement>) => void;
};

const InputTag = styled.input<Props>`
  color: ${Colors.DARK_CHARCOAL};
  font-family: "UOLdisplay";
  font-style: normal;
  font-weight: 400;
  line-height: 22px;

  padding: 14px 16px;
  border: 1px solid ${Colors.SILVER};
  background: ${Colors.WHITE};

  ${({ disabled }) => disabled && `disabled: ${disabled};`}
  ${({ fontSize }) => fontSize && `font-size: ${fontSize};`}
`;

const InputText: React.FC<Props> = ({
  id,
  placeHolder,
  disabled,
  fontSize,
  type,
  inputMode,
  value,
  onChange,
}) => {
  return (
    <InputTag
      id={id}
      name={id}
      disabled={disabled}
      fontSize={fontSize}
      type={type}
      inputMode={inputMode}
      placeHolder={placeHolder}
      value={value}
      onChange={onChange}
    />
  );
};

export default InputText;
