import { ReactNode } from "react";

import styled from "styled-components";

import { AlignItemsType } from "./types";

interface Props {
  name?: string;
  flex?: string;
  display?: string;
  gridTemplateColumns?: string;
  overflow?: string;
  flexShrink?: string;
  flexWrap?: string;
  flexDirection?: string;
  justifyContent?: string;
  alignItems?: AlignItemsType;
  backgroundColor?: string;
  border?: string;
  borderRadius?: string;
  padding?: string;
  margin?: string;
  width?: string;
  maxWidth?: string;
  minWidth?: string;
  height?: string;
  maxHeight?: string;
  boxShadow?: string;
  position?: string;
  bottom?: number | string;
  gap?: string;
  children?: ReactNode;
}

const DivTag = styled.div<Props | HTMLDivElement>`
  ${({ name }) => name && `id: ${name};`};
  ${({ name }) => name && `name: ${name};`};
  ${({ display }) => display && `display: ${display};`};
  ${({ gridTemplateColumns }) =>
    gridTemplateColumns && `grid-template-columns: ${gridTemplateColumns};`};
  ${({ overflow }) => overflow && `overflow: ${overflow};`};
  ${({ flexShrink }) => flexShrink && `flex-shrink: ${flexShrink};`};
  ${({ flex }) => flex && `flex: ${flex};`};
  ${({ flexWrap }) => flexWrap && `flex-wrap: ${flexWrap};`};
  ${({ border }) => border && `border: ${border};`};
  ${({ borderRadius }) => borderRadius && `border-radius: ${borderRadius};`};
  ${({ flexDirection }) =>
    flexDirection && `flex-direction: ${flexDirection};`};
  ${({ alignItems }) => alignItems && `align-items: ${alignItems};`};
  ${({ backgroundColor }) =>
    backgroundColor && `background-color: ${backgroundColor};`};
  ${({ justifyContent }) =>
    justifyContent && `justify-content: ${justifyContent};`};
  ${({ padding }) => padding && `padding: ${padding};`};
  ${({ margin }) => margin && `margin: ${margin};`};
  ${({ width }) => width && `width: ${width};`};
  ${({ maxWidth }) => maxWidth && `max-width: ${maxWidth};`};
  ${({ minWidth }) => minWidth && `min-width: ${minWidth};`};
  ${({ height }) => height && `height: ${height};`};
  ${({ maxHeight }) => maxHeight && `max-height: ${maxHeight};`};
  ${({ boxShadow }) => boxShadow && `box-shadow: ${boxShadow};`};
  ${({ position }) => position && `position: ${position};`};
  ${({ bottom }) => bottom && `bottom: ${bottom};`};
  ${({ gap }) => gap && `gap: ${gap};`};
`;

const Wrapper: React.FC<Props> = ({
  name,
  display,
  gridTemplateColumns,
  flex,
  overflow,
  flexShrink,
  flexDirection,
  justifyContent,
  alignItems,
  backgroundColor,
  border,
  borderRadius,
  padding,
  margin,
  width,
  maxWidth,
  minWidth,
  height,
  maxHeight,
  boxShadow,
  position,
  bottom,
  gap,
  children,
  ...rest
}) => {
  return (
    <DivTag
      id={name}
      name={name}
      display={display}
      gridTemplateColumns={gridTemplateColumns}
      flex={flex}
      overflow={overflow}
      flexShrink={flexShrink}
      flexDirection={flexDirection}
      justifyContent={justifyContent}
      alignItems={alignItems}
      backgroundColor={backgroundColor}
      border={border}
      borderRadius={borderRadius}
      padding={padding}
      margin={margin}
      width={width}
      maxWidth={maxWidth}
      minWidth={minWidth}
      height={height}
      maxHeight={maxHeight}
      boxShadow={boxShadow}
      position={position}
      bottom={bottom}
      gap={gap}
      {...rest}
    >
      {children && children}
    </DivTag>
  );
};

export default Wrapper;
