import React from "react";

import { useMediaQuery } from "../../hooks";

import Image from "../shared/Image";
import Wrapper from "../shared/Wrapper";
import Paragraph from "../shared/Paragraph";

import { Strings, Configs, Colors } from "../../constants";

import { LockSVG, PagBankWhiteSVG } from "../../assets/images";

const Footer: React.FC = () => {
  const isMobile = useMediaQuery(Configs.MOBILE_SIZE);
  const copyRights = isMobile
    ? Strings.COPY_RIGHTS_MOBILE
    : Strings.COPY_RIGHTS;

  return (
    <footer>
      <Wrapper display="flex" flexDirection="column" width="100%">
        {isMobile ? (
          <></>
        ) : (
          <Wrapper
            display="flex"
            backgroundColor={Colors.SHARK}
            justifyContent="center"
            alignItems="center"
            min-height="62px"
            flexShrink="0"
          >
            <Image src={LockSVG} margin="0px 9px 0px 0px" />
            <Paragraph
              color={Colors.DUSTY_GRAY}
              fontSize="12px"
              fontWeight="300"
            >
              Todas as páginas do PagBank estão em ambiente seguro.{" "}
              <a href="http://www.pagbank.com.br">Saiba mais</a>
            </Paragraph>
          </Wrapper>
        )}
        <Wrapper
          display="flex"
          flexDirection="column"
          backgroundColor={Colors.SHARKISH}
          width="100%"
          min-height="128px"
          justifyContent="center"
          alignItems="center"
        >
          <Image src={PagBankWhiteSVG} margin="23px 0px 0px 0px" />
          <Paragraph
            color={Colors.DOVE_GREY}
            textAlign="center"
            fontSize="13px"
            fontWeight="400"
            lineHeight="normal"
            margin="10px 0px 7px 0px"
          >
            {copyRights}
          </Paragraph>
          <Wrapper
            display="flex"
            justifyContent="center"
            alignItems="center"
            padding={isMobile ? "0px 50px" : "0px"}
          >
            <Paragraph
              color={Colors.DOVE_GREY}
              textAlign="center"
              fontSize="13px"
              fontWeight="400"
              lineHeight="normal"
            >
              PAGSEGURO INTERNET S/A - CNPJ/MF 08.561.701/0001-01 Av. Brigadeiro
              Faria Lima, 1.384, São Paulo - SP - CEP 01452-002
            </Paragraph>
          </Wrapper>
        </Wrapper>
      </Wrapper>
    </footer>
  );
};

export default Footer;
