import React from "react";

import Separator from "../shared/Separator";

import {
  Cartao1X1SVG,
  CertificadoSVG,
  MoneySVG,
  SegurancaSVG,
} from "../../assets/images";

import Span from "../shared/Span";
import Image from "../shared/Image";
import Wrapper from "../shared/Wrapper";

import { Colors } from "../../constants";

type Props = {
  isMobile?: boolean;
  title: string;
  subTitle: string;
  icon: string;
};

const Card = ({ isMobile = false, title, subTitle, icon }: Props) => {
  return (
    <Wrapper
      display="flex"
      flexDirection="column"
      maxWidth={isMobile ? "145px;" : "230px"}
      height={isMobile ? "168px" : "202px"}
      padding="19.88px"
      backgroundColor={Colors.WHITE}
      borderRadius="5.68px"
      border={`1.42px ${Colors.MERCURY} solid`}
      alignItems="center"
      gap={isMobile ? "15px" : "19.88px"}
    >
      <Image
        src={icon}
        width={isMobile ? "36.364px" : "56.813px"}
        height={isMobile ? "36.364px" : "56.813px"}
      />
      <Wrapper
        display="flex"
        flexDirection="column"
        height={isMobile ? "25px" : "17px"}
        padding="8px"
      >
        <Span
          color={Colors.BLACK}
          fontSize={isMobile ? "11px" : "17.186px"}
          fontWeight="700"
          textAlign="center"
          uppercase
        >
          {title}
        </Span>
      </Wrapper>
      <Separator width="100%" border="1.42px #E7E7E7 solid" />
      <Span
        color={Colors.SCORPION}
        fontWeight="400"
        fontSize={isMobile ? "11px" : "17.044px"}
        textAlign="center"
      >
        {subTitle}
      </Span>
    </Wrapper>
  );
};

type AdvantagesType = {
  isMobile?: boolean;
};

const Advantages: React.FC<AdvantagesType> = ({ isMobile }) => {
  return (
    <Wrapper
      display={isMobile ? "grid" : "flex"}
      gridTemplateColumns={isMobile ? "auto auto" : "auto auto auto auto"}
      flexWrap="wrap"
      justifyContent="center"
      alignItems="center"
      padding="0 27px"
      gap={isMobile ? "15px" : "35px"}
    >
      <Card
        isMobile={isMobile}
        title="Segurança para seus clientes"
        subTitle="Suporte 24h"
        icon={SegurancaSVG}
      />
      <Card
        isMobile={isMobile}
        title="aceite as principais bandeiras"
        subTitle="Mais de 25 cartões nacionais e internacionais"
        icon={Cartao1X1SVG}
      />
      <Card
        isMobile={isMobile}
        title="CONFIANÇA  E CREDIBILIDADE"
        subTitle="Somos uma das principais empresas de pagamentos"
        icon={CertificadoSVG}
      />
      <Card
        isMobile={isMobile}
        title="VENDA MAIS"
        subTitle="Mais opções de pagamentos"
        icon={MoneySVG}
      />
    </Wrapper>
  );
};

export default Advantages;
